.playlist {
    width: 100px;
    margin: 10px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer; 
}

.playlistImage {
    height: 100px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    color:white;
    display:flex;
    justify-content: center;
    align-items: center;
}

.loader-container {
    text-align: center;
    background: rgba(255, 255, 255, .1);
    border-radius: 4px;
    height:100%;
    width:100%;
    padding:30px 50px;
}

.results-header {
    border: 1px solid grey;
    padding: 0px 15px !important;
    background-color: rgb(19, 19, 19) !important;
}

.secondaryText {
    color:grey !important;
}

.ant-list-item {
    border-color:rgb(58, 58, 58) !important;
}

.ant-list-item-meta-title,  .ant-list-item-meta{
    margin-bottom:5px !important;
}

.ant-list-item-action {
    margin-top:10px !important;
}