@import '~antd/dist/antd.css';

.App {
  background-color:#1A1A1B;
}

.darkInput, .darkInput input, .darkInput button {
  background-color: rgb(1,1,1,1);
  color:white !important;
  border: 1px solid grey;
}

.darkinput

.darkInput svg {
  color:white;
}



/*Dark Styling for Ant Table*/
.ant-table {
  background-color:rgba(1,1,1,1) !important;
  color:white;
}

.ant-table-cell {
  color:white !important;
  background-color:rgba(10,10,10,1) !important;
  border-bottom:1px solid rgb(58, 57, 57) !important;
}

.ant-table-pagination a {
  color:white !important;
}

.ant-table-pagination .ant-pagination-item-active {
  background-color:black !important;
}

.ant-table-wrapper .ant-spin-blur {
  opacity:0.3 !important;
}

.ant-table-cell path, .ant-table-cell ellipse {
  fill:grey !important;
}

/*Dark Styling for Ant Menu*/
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
  background-color:rgba(10,10,10,1) !important;
}



.crossfader {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #d3d3d3;
  outline: none;
}

.crossfader::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 55px;
  border: 0;
  background: url('./images/fader_vertical.svg');
  background-repeat:none;
  background-size: 100% 100%;
  cursor: pointer;
}

.crossfader::-moz-range-thumb {
  width: 20px;
  height: 55px;
  border: 0;
  background: url('./images/fader_vertical.svg');
  background-repeat:none;
  background-size: 100% 100%;
  cursor: pointer;
}

.tempo_slider {
  transform: rotate(-90deg);
  -webkit-appearance: none;
  height: 5px;
  width: 60px;
  background: #d3d3d3;
  outline: none;
}

.tempo_slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 55px;
  border: 0;
  background: url("./images/fader_vertical.svg");
  background-repeat: none;
  background-size: 100% 100%;
  cursor: pointer;
}

.tempo_slider::-moz-range-thumb {
  width: 20px;
  height: 55px;
  border: 0;
  background: url("./images/fader_vertical.svg");
  background-repeat: none;
  background-size: 100% 100%;
  cursor: pointer;
}
